<template>
  <div :style="{backgroundImage:`url(${images.background})`}" style="background-position: left bottom; background-repeat: repeat-x">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true">
    </loading>
    <div id="router" class="pt-10" style="background:#FFF" :style="[{'height' : (haveToken) ? 'calc(100vh)' : (innerWidth > 959) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
      <div v-if="haveToken" class="col-12 col-md-6 ma-0 pa-0" align="center"> 
        <div class="ma-1" style="position: absolute; top:0px; left:0px; background:#222; border-radius: 30px; height:36px; width:36px; opacity: 0.7; z-index:5"
        @click="redirectPath('/couponPage')">
          <v-icon class="ma-1" size="28px" color="#FFF">mdi-menu-left</v-icon>
        </div>
      </div>
      <v-row style="margin: 1%; justify-content:center"
      :style="[{'margin-right' : (this.displayMode == 0) ? '10%' : '0%'},
      {'margin-left' : (this.displayMode == 0) ? '10%' : '0%'}]">
        <v-col class="col-12 col-md-6 ma-0 pa-0" align="center" style="position: relative"> 
          <div v-if="!haveToken" class="ma-1" style="position: absolute; top:0px; left:0px; background:#222; border-radius: 30px; height:36px; width:36px; opacity: 0.7; z-index:5"
          @click="redirectPath('/couponPage')">
            <v-icon class="ma-1" size="28px" color="#FFF">mdi-menu-left</v-icon>
          </div>
          <v-img :src="datasets.mainImageImage" width=500 alt="action_04"
          :style="{'opacity' : (datasets.totalAmount && datasets.totalAmount == datasets.usedAmount) ? '40%' : '100%'}"></v-img>
          <v-img v-if="datasets.totalAmount && datasets.totalAmount == datasets.usedAmount" class="ma-0 pa-10"
            :src="images.finish" alt="finish pic"
            style="position:absolute; bottom:10px; right:10px; width:160px; height:160px; transform: rotate(-12deg)"
          ></v-img>
        </v-col>
        <v-col class="col-12 col-md-4 px-2 text-left">
          <h3 class="start">{{datasets.productFeature}}</h3>
          <!-- <v-row class="ma-0 pa-0 text-left weight600">
            <h1>{{datasets.productName}}</h1>
            <h3 class="mt-5">/{{datasets.totalAmount}}</h3>
          </v-row> -->
          <h1>{{datasets.productName}}</h1>
          <h4 v-if="datasets.totalAmount>10">{{$t('__remainingStock')}}>10</h4>
          <h4 v-if="datasets.totalAmount<=10">{{$t('__remainingStock')}}={{datasets.totalAmount}}</h4>
          <div id="router" class="mt-5" v-html="datasets.description" style="width:100%; height:260px"></div>
          <v-btn v-if="!exchange" class="styleBtn2" color="#B1B1B1" style="color:#FFF; width:100%">{{$t('__btnContinue')}}</v-btn>
          <v-btn v-if="exchange" class="styleBtn2" color="#4880C8" style="color:#FFF; width:100%"
          @click="$refs.ShowConfirm.open(1,'','',true)">{{$t('__btnRedeem')}}</v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" style="justify-content:center"
      :style="[{'margin-right' : (this.displayMode == 0) ? '10%' : '0%'},
      {'margin-left' : (this.displayMode == 0) ? '10%' : '0%'}]">
        <v-col class="col-12 col-md-10 ma-0 pa-0">
          <v-row class="ma-0 pa-0" style="justify-content:center">
            <v-col class="ma-0 pa-0 mx-1">
              <v-btn class="styleBtn2" :outlined="(showInfo!=0)?true:false" color="#4880C8" :style="{'color' : (showInfo!=0) ? '#4880C8' : '#FFF'}"
              style="width:100%" @click="showInfo=0">{{$t('__introductionProduct')}}</v-btn>
            </v-col>
            <v-col class="ma-0 pa-0 mx-1">
              <v-btn class="styleBtn2" :outlined="(showInfo!=1)?true:false" color="#4880C8" :style="{'color' : (showInfo!=1) ? '#4880C8' : '#FFF'}"
              style="width:100%" @click="showInfo=1">{{$t('__introductionStore')}}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="showInfo==0" style="margin: 1%; justify-content:center"
      :style="[{'margin-right' : (this.displayMode == 0) ? '10%' : '0%'},
      {'margin-left' : (this.displayMode == 0) ? '10%' : '0%'}]">
        <v-col class="col-12 col-md-10 ma-0 pa-0">
          <v-img :src="datasets.productImage1Image" alt="productImage1"></v-img>
          <v-img :src="datasets.productImage2Image" alt="productImage2"></v-img>
          <v-img :src="datasets.productImage3Image" alt="productImage3"></v-img>
          <v-img :src="datasets.productImage4Image" alt="productImage4"></v-img>
        </v-col>
      </v-row>
      <v-row v-if="showInfo==1" style="margin: 1%; justify-content:center"
      :style="[{'margin-right' : (this.displayMode == 0) ? '10%' : '0%'},
      {'margin-left' : (this.displayMode == 0) ? '10%' : '0%'}]">
        <v-col class="col-12 col-md-10 ma-0 pa-0; justify-content:center">
          <div id="router" class="mt-5 text-left" v-html="datasets.manufacturerDesc" style="width:100%; height:800px"></div>
        </v-col>
      </v-row>
      <div style="width: 100%">
        <MainFooter ref="MainFooter" />
      </div>
    </div>
    <div class="goTop iconfont ml-gotop" :class="isTop ? 'goTopAfter' : ''" @click="goTop()">
      <h1 class="my-3 mx-2 contentTitle" style="font-weight: 700; color: #FFF; font-size: 20px">TOP</h1>
    </div>
    <ShowMsg ref="ShowMsg" :onSave="onShowMsg"/>
    <ShowConfirm ref="ShowConfirm" :onSave="onShowConfirm"/>
  </div>
</template>

<script>
import ShowMsg from '@/dialogs/ShowMsg'
import ShowConfirm from '@/dialogs/ShowConfirm'
import MainFooter from '@/components/MainFooter.vue'
import i18n from '@/plugins/i18n'
import moment from 'moment'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.min.css'
export default {
  name: 'CouponDetail',
  components: {ShowMsg,ShowConfirm,Loading,MainFooter},
  props: {},
  mounted(){
    this.language = i18n.locale
    this.exchange = (sessionStorage.getItem('totalDietDays') == 'true') ? true : false
    this.getDataset()
    
    window.addEventListener("scroll", () => {
      let top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      this.scrollNum = top;
      if (top >= 20) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    });
  },
  data:() => ({
    language:'',
    datasets:[],
    haveToken: false,
    innerWidth: 0,
    showInfo:0,
    images:{
      background: require('@/assets/image/background-2000.png'),
      finish: require('@/assets/image/finish.png'),
    },
    isLoading: false,
    exchange: false,
    content:'',
    imageItemList: ['','','','',''],
    scrollNum: 0, //滾動距離
    isTop: false, //是否顯示回到頂部按鈕
    displayMode:'',
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  watch:{
    "language":function(){
      this.changeLanguageSel()
    },
  },
  methods:{
    calculateWindows(){
      if(this.$cookie.get('token')){
        this.haveToken = true
      }
      this.innerWidth = window.innerWidth
      if(window.innerWidth >= 960){
        this.displayMode = 0
      } else {
        this.displayMode = 1
      }
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    changeLanguageSel(){
      if(this.language=='tw'){
        this.content = this.datasets.twContent
      }else if(this.language=='cn'){
        this.content = this.datasets.cnContent
      }else if(this.language=='en'){
        this.content = this.datasets.enContent
      }
    },
    getDataset(){
      var self = this
      var request = {
        method: 'GET',
        url: '/couponOption/' + this.$route.query.id,
        params: {},
      }
      this.$http(request).then(function(response) {
        self.datasets = response.data.data
      }).catch(()=>{
      }).finally(()=>{
        //update imageItemList
        let tmp1 = self.datasets
        for(var i=0; i<5; i++){
          if(i == 0){
            if(tmp1.mainImage){
              self.$set(tmp1, 'mainImageImage', self.$Global.getUrlImage() + tmp1.mainImage)
              self.imageItemList[0] = tmp1.mainImageImage
            }
          }else{
            if(tmp1['productImage' + i]){
              self.$set(tmp1, 'productImage' + i + 'Image', self.$Global.getUrlImage() + tmp1['productImage' + i])
              self.imageItemList[i] = tmp1['productImage' + i + 'Image']
            }
          }
        }
      })
    },
    setDataset(){
      this.isLoading = true
      var allData = {}
      allData = this.$Global.jsonConcat(allData,{'couponOptionId':this.datasets.id})
      allData = this.$Global.jsonConcat(allData,{'productNumber':this.datasets.productNumber})
      allData = this.$Global.jsonConcat(allData,{'manufacturerName':this.datasets.manufacturerName})
      allData = this.$Global.jsonConcat(allData,{'productName':this.datasets.productName})
      allData = this.$Global.jsonConcat(allData,{'availablePeriodStart':this.datasets.availablePeriodStart})
      allData = this.$Global.jsonConcat(allData,{'availablePeriodEnd':this.datasets.availablePeriodEnd})
      // allData = this.$Global.jsonConcat(allData,{'couponPwd':this.datasets.couponPwd})
      allData = this.$Global.jsonConcat(allData,{'languageSystem':this.language})
      allData = this.$Global.jsonConcat(allData,{'memberId':sessionStorage.getItem('myCalId')})
      allData = this.$Global.jsonConcat(allData,{'memberName':sessionStorage.getItem('myCalName')})
      allData = this.$Global.jsonConcat(allData,{'exchangeDate': moment(new Date()).format('YYYY-MM-DD')})
      allData = this.$Global.jsonConcat(allData,{'useStatus':'2'})
      allData = this.$Global.jsonConcat(allData,{'note2':sessionStorage.getItem('myCalEmail')})
      var self = this
      var request = {
        method: 'POST',
        url: '/mCouponOptionList',
        data: this.Qs.stringify(allData)
      }
      this.$http(request).then(function(response) {
        if(response.data.msg == '未達30天'){
          self.$refs.ShowMsg.open(14,'','',true,true)
        }else{
          self.$refs.ShowMsg.open(8,'','',true,true)
        }
        self.isLoading = false
      }).catch(()=>{
        self.isLoading = false
      }).finally(()=>{
      })
    },
    goTop(){
      document.documentElement.scrollTop = 0;
    },
    onShowConfirm(){
      this.setDataset()
    },
    onShowMsg(){
      this.redirectPath('/couponList')
    }
  }
}
</script>

