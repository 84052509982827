const ProjectName = 'GOTOP';
const ipAddr = 'rainbowapps.asia/api';
// const ipAddr = '192.168.50.29:8080';
// const ipAddr = 'chihta-software.com/study-0.0.1-GOTOP';

var getAPIURL = function(){
	sessionStorage.setItem('ipAddr', ipAddr)

	// return "http://localhost:8086";
	// return "http://192.168.100.7:8087";
	// return "http://52.194.186.142:8087";
	// return 'http://' + ipAddr;
	return 'https://' + ipAddr;
	
	// HTTPS SERVER
	// return "https://chihta-software.com/study-0.0.1-SNAPSHOT"; //更新版本號
	// return "https://13.114.173.137/study-0.0.1-SNAPSHOT";
}

const CONFIG = {

	/**

	* [API_URL]

	* @description: 全站發 api 的 endpoint

	*/
	PROJECT_NAME:ProjectName,
	API_URL: getAPIURL(),

}

export { CONFIG };
