<template>
  <div :style="{backgroundImage:`url(${images.background})`}" style="background-position: left bottom; background-repeat: repeat-x">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true">
    </loading>
    <div id="router" class="pt-10" :style="[{'height' : (haveToken) ? 'calc(100vh)' : (innerWidth > 959) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
      <v-row style="margin: 0% 10%; justify-content:center">
        <v-btn v-if="innerWidth > 700" class="styleBtn2 mx-1" :outlined="(showInfo!=0)?true:false" color="#4880C8" :style="{'color' : (showInfo!=0) ? '#4880C8' : '#FFF'}"
        style="min-width:180px" @click="showInfo=0">{{$t('__couponStatus0')}}{{$t('__coupon')}}</v-btn>
        <v-btn v-if="innerWidth > 700" class="styleBtn2 mx-1" :outlined="(showInfo!=1)?true:false" color="#4880C8" :style="{'color' : (showInfo!=1) ? '#4880C8' : '#FFF'}"
        style="min-width:180px" @click="showInfo=1">{{$t('__couponStatus1')}}{{$t('__coupon')}}</v-btn>
        <v-select v-if="innerWidth <= 700" class="text_field" dense background-color="white" outlined style="max-width:50%"
        :items="btnList" :item-text="(language=='cn') ? 'cnName' : (language=='en') ? 'enName' : 'twName'" item-value="valuev" v-model="showInfo">
        </v-select>
      </v-row>

      <v-row v-if="innerWidth > 700" style="margin: 0%; justify-content:center"
      :style="[{'margin-right' : (this.displayMode == 0) ? '10%' : '0%'},
      {'margin-left' : (this.displayMode == 0) ? '10%' : '0%'}]">
        <v-row class="ma-0 pa-0" style="overflow-x:hidden; overflow-y:hidden">
          <div id="listTable" style="height: 600px">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr class="text-center">
                    <th @click="sort('id')">{{$t('__redemptionNumber')}}<v-icon small>icon-form_sort_off</v-icon></th>
                    <th @click="sort('manufacturerName')">{{$t('__manufacturerName')}}<v-icon small>icon-form_sort_off</v-icon></th>
                    <th @click="sort('productName')">{{$t('__productName')}}<v-icon small>icon-form_sort_off</v-icon></th>
                    <th @click="sort('exchangeDate')">{{$t('__applicationTime')}}<v-icon small>icon-form_sort_off</v-icon></th>
                    <th v-if="showInfo==1" @click="sort('useDate')">{{$t('__sendTime')}}<v-icon small>icon-form_sort_off</v-icon></th>
                    <!-- <th @click="sort('availablePeriodStart')">{{$t('__usageInterval')}}<v-icon small>icon-form_sort_off</v-icon></th> -->
                    <!-- <th>{{$t('__useButton')}}</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data,index) in datasets" :key="index" id="table_body">
                    <td style="min-width:180px"><h4 class="content">{{data.id}}</h4></td>
                    <td style="min-width:180px"><h4 class="content">{{data.manufacturerName}}</h4></td>
                    <td style="min-width:240px"><h4 class="content">{{data.productName}}</h4></td>
                    <td style="min-width:160px"><h4 class="content">{{data.exchangeDate}}</h4></td>
                    <td v-if="showInfo==1" style="min-width:160px"><h4 class="content">{{data.useDate}}</h4></td>
                    <!-- <td style="min-width:220px"><h4 class="content">{{data.availablePeriodStart}}~{{data.availablePeriodEnd}}</h4></td> -->
                    <!-- <td style="min-width:120px">
                      <v-btn v-if="showInfo==0 && data.useStatus == '2'" color="#4880C8" style="color:#FFF"
                      @click="$refs.ShowConfirm.open(4,'','',true,data.id)">
                        <v-icon>mdi-ticket-percent-outline</v-icon>
                        <h4>{{$t('__couponUse')}}</h4>
                      </v-btn>
                      <v-btn v-if="showInfo==1 && data.useStatus == '1'" color="#B1B1B1" style="color:#FFF">
                        <v-icon>mdi-ticket-percent-outline</v-icon>
                        <h4>{{$t('__couponUsed')}}</h4>
                      </v-btn>
                    </td> -->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-row>
      </v-row>
      <v-row v-else class="ma-0 pa-0" style="justify-content:center">
        <v-row class="mx-4 my-2 pa-0" style="width:100%; background:#FFF; border-radius: 8px; border:2px solid #4880C8; justify-content:space-between"
         v-for="(data,index) in datasets" :key="index">
          <v-col class="py-3 pl-5 pr-1 col-11 text-left">
            <h4 style="min-width:120px; color:#666" class="multilineEllipsis2">({{data.id}}) {{data.manufacturerName}}</h4>
            <h3 style="min-width:140px; color:#4880C8" class="multilineEllipsis2">{{data.productName}}</h3>
            <h5>
              <span>{{$t('__applicationTime')}}：{{data.exchangeDate}}</span>
              <span v-if="showInfo==1"> | {{$t('__sendTime')}}：{{data.useDate}}</span>
            </h5>
          </v-col>
          <!-- <v-col class="py-3 pl-0 pr-5 col-3 text-right" style="align-self:center">
            <v-btn v-if="showInfo==0 && data.useStatus == '2'" class="pa-3" outlined style="height:40px; background:#4880C8; border-radius: 200px; color:#4880C8"
            @click="$refs.ShowConfirm.open(4,'','',true,data.id)">
              <h4 class="center" style="color:#FFF">{{$t('__couponUse')}}</h4>
            </v-btn>
            <v-btn v-if="showInfo==1 && data.useStatus == '1'" class="pa-3" outlined style="height:40px; background:#B1B1B1; border-radius: 200px; color:#B1B1B1">
              <h4 class="center" style="color:#FFF">{{$t('__couponUsed')}}</h4>
            </v-btn>
          </v-col> -->
        </v-row>
      </v-row>
    </div>
    <div v-if="haveToken" style="position: absolute; top:10px; left:10px;"> 
      <div class="ma-1" style="background:#222; border-radius: 30px; height:36px; width:36px; opacity: 0.7; z-index:5"
      @click="redirectPath('/couponPage')">
        <v-icon class="ma-1" size="28px" color="#FFF">mdi-menu-left</v-icon>
      </div>
      <v-img :src="datasets.mainImageImage" width=500 alt="action_04"></v-img>
    </div>
    <ShowMsg ref="ShowMsg" :onSave="onShowMsg"/>
    <ShowConfirm ref="ShowConfirm" :onSave="onShowConfirm"/>
  </div>
</template>

<script>
import ShowMsg from '@/dialogs/ShowMsg'
import ShowConfirm from '@/dialogs/ShowConfirm'
import i18n from '@/plugins/i18n'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.min.css'
export default {
  name: 'CouponList',
  components: {ShowMsg,ShowConfirm,Loading},
  mounted(){
    this.language = i18n.locale
    this.getDataset()
  },
  data:() => ({
    language:'',
    showInfo:0,
    datasets:[],
    haveToken: false,
    innerWidth: 0,
    images:{
      background: require('@/assets/image/background-2000.png'),
    },
    isLoading: false,
    btnList:[
      {twName:'兌換中',cnName:'兑换中',enName:'Redeeming',valuev:0},
      {twName:'已發送',cnName:'已发送',enName:'Sent',valuev:1},
    ],
    content:'',
    displayMode:'',
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  watch:{
    "language":function(){
      this.changeLanguageSel()
    },
    "showInfo":function(){
      this.getDataset()
    },
  },
  methods:{
    calculateWindows(){
      if(this.$cookie.get('token')){
        this.haveToken = true
      }
      this.innerWidth = window.innerWidth
      if(window.innerWidth >= 960){
        this.displayMode = 0
      } else {
        this.displayMode = 1
      }
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    changeLanguageSel(){
      if(this.language=='tw'){
        this.content = this.datasets.twContent
      }else if(this.language=='cn'){
        this.content = this.datasets.cnContent
      }else if(this.language=='en'){
        this.content = this.datasets.enContent
      }
    },
    getDataset(){
      var self = this
      var request = {
        method: 'GET',
        url: '/mCouponOptionListByMemberId',
        params: {
          'memberId': sessionStorage.getItem('myCalId'),
          'useStatus': (self.showInfo=='0' ? '0' : self.showInfo=='1' ? '1' :  '2'),
        },
      }
      this.$http(request).then(function(response) {
        self.datasets = response.data.data
      }).catch(()=>{
      })
    },
    onShowConfirm(id){
      this.isLoading = true
      var self = this
      var request = {
        method: 'PUT',
        url: '/mCouponOptionListUseStatusUpdate/' + id,
      }
      this.$http(request).then(function(response) {
        self.$refs.ShowMsg.open(3,'','',true,true)
        self.isLoading = false
      }).catch(()=>{
        self.isLoading = false
      })
    },
    onShowMsg(){
      this.getDataset()
    }
  }
}
</script>