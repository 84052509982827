/* /tw/lang.js */
export default {
  __homePage: '首頁',
  __aboutMyCal: '關於我的卡路里',
  __privacyPage: '隱私權服務',
  __storageUpgrade: '加值服務',
  __couponExchange: '紅利兌換',
  __contactUs: '聯絡我們',
  __languageTw: '繁中',
  __languageCn: '簡中',
  __languageEn: '英文',
  __login: '登入',
  __logout: '登出',
  __record: '加值紀錄',
  __coupon: '優惠券',
  __myCouponList: '我的優惠券',

  // footer homePage
  __companyDetail: '我的卡路里 | 詳細介紹',
  __companyName: '瑞寶智能股份有限公司',
  __unifiedBusinessNo: '公司統編：89132638',
  __companyAddress: '登記地址：新北市板橋區館前東路20號9樓',
  __companyEmail: '聯絡我們：service@rainbowapps.asia',
  __copyright: 'Copyright © 2024 瑞寶智能股份有限公司 版權所有',

  // universal
  __languageSetting: '語系選擇',
  __select:'請選擇',
  __enter:'請輸入',
  __selectFile: '選擇檔案',
  __createTime: '建立時間',
  __updateTime: '修改時間',
  __completeInformation: '完整資料',
  __content:'內容',
  __back:'返回',
  __save: '儲存',
  __new: '新增',
  __confirm: '確定',
  __cancel: '取消',
  __settings: '設定',
  __keywordSearch: '關鍵字查詢',
  __categorySearch: '依據類別查詢',
  __edit: '編輯',
  __total: '共',
  __total2: '筆',
  __go: '前往',
  __page: '頁',
  __announcementTitle: '系統公告',
  __announcementCheckbox: '今日不再提醒。',

  // LoginPage
  __memberLogin: '會員登入',
  __managerUserId: '帳號',
  __managerEmail: '信箱',
  __enterUserId: '請輸入使用者名稱',
  __enterUserEmail: '請輸入信箱',
  __managerPassword: '密碼',
  __entermanagerPassword: '請輸入密碼',
  __isError: '帳號或密碼輸入錯誤 !',

  // aboutMyCal
  __operationBase: '運營據點',
  __linkSingapore: '新加坡',
  __linkSingaporeContent: '亞太區業務和市場開發中心',
  __linkTaiwan: '台灣',
  __linkTaiwanContent: '系統開發和維護中心',
  __linkChina: '中國',
  __linkChinaContent: '運營發展中心',
  __linkMalaysia: '馬來西亞',
  __linkMalaysiaContent: '市場發展中心',
  __linkCambodia: '柬埔寨',
  __linkCambodiaContent: '運營發展中心',
  __linkPhilippines: '菲律賓',
  __linkPhilippinesContent: '市場發展中心',

  // ShowMsg
  __requiredTitle: '資訊不完整',
  __requiredContent: '請輸入所有必填',
  __privacyApplyContent: '請確認要撤回的項目',
  __newTitle: '已新增資料',
  __newContent: '系統已新增此筆資料',
  __revisedTitle: '已修改資料',
  __revisedContent: '系統已修改此筆資料',
  __deleteTitle: '已刪除資料',
  __deleteContent: '系統已刪除此筆資料',
  __importTitle: '已匯入',
  __importContent: '系統已將資料匯入!',
  __sendTitle: '送出成功',
  __sendContent: '誠摯感謝您的詢問，我們將儘速以電子郵件回應您。',
  __applyTitle: '申請成功',
  __applyContent: '感謝您的申請。收到付款後，將處理您的申請。',
  __couponTitle: '兌換成功',
  __couponContent: '感謝您的兌換，請於期限內使用。',
  __couponTitle2: '兌換成功',
  __couponContent2: '感謝您的兌換，請持續使用我的卡路里。',
  __couponTitle3: '兌換失敗',
  __couponContent3: '您尚未累積三十天紅利，請繼續加油。',
  __privacyTitle: '撤回成功',
  __privacyContent: '系統已將您的個人資料撤回。',
  __loginTitle: '登入失敗',
  __loginContent: '該帳號已停用，無法登入。',
  __imageTitle: '圖片不符合規定',
  __imageContent: '請確認圖片小於50KB，且圖片寬度750像素，高度388像素。',
  __imageSizeTitle: '圖片不符合規定',
  __imageSize50Content: '請確認圖片小於50KB。',
  __imageSize80Content: '請確認圖片小於80KB。',

  // ShowConfirm
  __couponConfirmTitle: '確定兌換',
  __couponConfirmContent: '您確定要進行兌換嗎 ? 兌換後，優惠券將於下月五日寄送至您的帳號信箱（信箱無法更改）。請注意查收，恕不另行通知。',
  __deleteConfirmTitle: '確定刪除',
  __deleteConfirmContent: '您確定要刪除此筆資料嗎 ?',
  __privacyConfirmTitle1: '確定撤回',
  __privacyConfirmContent1: '您確定要進行撤回嗎 ? 撤回後系統將不會使用您的資料進行後期與醫療相關之個人資訊研究，該帳號仍可持續使用該應用程式。',
  __privacyConfirmTitle2: '確定撤回',
  __privacyConfirmContent2: '您確定要進行撤回嗎 ? 撤回後系統將移除您的個人用戶資訊、餐點紀錄、優惠券等紀錄。請注意：點擊「確定」您將無法再登入系統使用任何功能。',

  // CouponDialogs
  __enterPassword: '兌換優惠券',
  __enterPasswordContent: '您是否確定要兌換優惠券？點擊「確定」後將進行兌換，且無法進行還原。',
  __enterPasswordError: '兌換密碼錯誤',
  __enterPasswordSuccess: '兌換',

  // contactUs
  __commonProblem: '常見問題',
  __contactQuestion: '洽詢問題',
  __contactName: '姓名',
  __contactEmail: '電子信箱',
  __contactQuestionType: '問題類型',
  __contactQuestionContent: '問題內容',
  __contactQuestionPictures: '附加圖片',
  __sendQuestions: '問題送出',

  // privacyPage
  __privacyConsent: '隱私權同意書',
  __privacyConsentWithdraw: '隱私權同意書撤回',
  __privacyQuestions: '對隱私權同意書有任何疑問，請',
  __privacyWithdraw: '撤回您的隱私權同意書，請',
  __privacyWithdrawClick: '點此撤回',
  __applicantInformation: '申請者資訊',
  __memberName: '使用者名稱',
  __memberEmail: '電子信箱',
  __privacyWithdrawItem1: '僅不同意個人資訊，可能用於與健康相關的研究，但該帳號仍可繼續使用本APP',
  __privacyWithdrawItem2: '不同意整份隱私權同意書，並同時註銷個人帳號及使用紀錄',
  __confirmWithdrawal: '確定撤回',

  // storageUpgrade storageUpgradeApply
  __applicationButton1: '申請加值',
  __applicationButton2: '服務按鈕',
  __memberExpiration: '會員到期日',
  __recordButton1: '加值紀錄',
  __recordButton2: '查看按鈕',
  __pricePurchasePlan: '購買方案',
  __priceOrderProgram: '選購方案',
  __priceProgramFee: '方案費用',
  __pricePeriod: '方案期間',
  __priceStartDate: '起始日期',
  __priceEndDate: '结束日期',
  __pricePaymentMethod: '付款方式',
  __pricePaymentfrequency: '續約模式',
  __priceImmediatePayment: '立即付款',
  __priceOrderDescription: '方案說明：費用包含會員使用及儲存個人卡路里/選餐記錄期限。',
  
  __orderRecord: '我的卡路里加值紀錄',
  __orderRecordMsg: '您目前還沒有任何加值紀錄',
  __orderProgramy: '加值方案',
  __paymentMethod: '付款方式',
  __paymentStatus: '繳費狀態',
  __orderNumber: '加值單號',
  __orderMemberId: '申請對象',
  __orderCreateTime: '申請時間',
  __programFee: '方案費用',
  __orderUpdateTime: '異動時間',
  __paymentTime: '付款時間',

  // CouponList couponDetail couponInfo
  __couponHello: '您好',
  __couponHelloMsg1: '已累計使用',
  __couponHelloMsg2: '天',
  __showDetail: '點擊查看詳情',
  __alartTitle: '溫馨提示：',
  __alart1: '請記得兌換優惠券',
  __alart2: '請持續努力將可兌換優惠券',
  __couponUse: '使用',
  __couponStatus0: '兌換中',
  __couponStatus1: '已發送',
  __redemptionNumber: '兌換單號',
  __redemptionProgram: '兌換方案',
  __manufacturerName: '廠商名稱',
  __productName: '商品名稱',
  __applicationTime: '申請時間',
  __sendTime: '發送時間',
  __usageInterval: '使用區間',
  __useButton: '使用按鈕',
  __remainingStock: '剩餘庫存',
  __btnContinue: '持續加油',
  __btnRedeem: '點擊兌換',
  __introductionProduct: '商品介紹',
  __introductionStore: '店家介紹',
  __couponInfoBtn1: '兌換機制',
  __couponInfoBtn2: '我的使用狀態',
  __couponInfoBtn3: '兌換紀錄',
  __couponList: '優惠券狀態',
  __functionMenu: '功能選單',
  __couponAll: '全選',
  __couponClear: '清空',
  __calendarDateInfo1: '已過期',
  __calendarDateInfo2: '已兌換',
  __calendarDateInfo3: '尚未兌換',
  __calendarDateInfo4: '今日',
}